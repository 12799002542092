.papercactus__slogan {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color:#e8e8e8;
    font-family: var(--font-family);
    text-align: center;
    background-color:#222222;
    z-index: 100;
    font-size: 1.3rem;
}

.papercactus__slogan div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:650px) {
    .mobile-gltich {
        font-size: 2.5rem;
    }
}

@media screen and (max-width:450px) {
    .mobile-gltich {
        font-size: 2.1rem;
    }
}
