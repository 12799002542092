.papercactus__break {
    position:relative;
    display: flex;
    justify-content: center;
    background-color:#222;
    height:50px;
}

.papercactus__break-image {
    position:absolute;
    width:100%;
    height:50px;
    object-fit:contain;
}

.papercactus__break-image-flipped {
    transform:scaleX(-1);
    position:absolute;
    width:100%;
    height:50px;
    object-fit:contain;
}