.papercactus__drone-column-container {
    display: flex;
    flex-direction: column;
    position: relative;
    color: #fff;
    background-color: #222222;
    padding-bottom: 5px !important;
  }

  .papercactus__drone-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .papercactus__drone-column-container h1 {
    font-family: var(--font-family);
    font-size: 2.5rem;
  }
  
  .papercactus__drone-column-container p {
    font-size: 1.6rem;
    font-family: var(--font-family2);
  }
  
  .papercactus__drone-paragraph-column {
    display: flex;
    flex-direction: column;
    max-width:800px;
    justify-content: center;
    align-items: flex-end;
    margin-left: 50px;
    z-index: 1;
  }
  
  .papercactus__drone-header-image {
    position:absolute;
    z-index:-1;
    width:250px;
    top:-35px;
    right:-20px;
  }
  
  .papercactus__drone-title-backing {
    width:370px;
    position: relative;
  }
  
  .papercactus__drone-paragraph {
    padding-bottom:55px;
  }

  .papercactus__drone-leftMarginFlipped {
    display: flex;
    width: 67px;
    justify-content: center;
    margin-right: 50px;
    flex-wrap: wrap;
    align-items: flex-end;
    transform: scaleX(-1);
    padding-right: 50px;
  }
  
  .papercactus__drone-deckDemo {
    width: 800px;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
  }
  
  .papercactus__drone-image-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  
  .papercactus__drone-image-container img {
    width: 300px;
    height: 300px;
  }

  .digitalDisplay1 {
    width:340px;
    position: absolute;
    top:40px;
    left:80px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  .digitalDisplay2 {
    width:250px;
    position: absolute;
    top:360px;
    left:120px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  .digitalDisplay1.animation {
    animation: dash 1.5s linear forwards;
  }
  
  .digitalDisplay2.animation {
    animation: dash 1.5s linear forwards;
    animation-delay: 0.5s;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  
@media screen and (max-width: 900px) {
    .papercactus__drone-paragraph-column p {
        font-size: 1.5rem;
    }

    .papercactus__drone-paragraph-column {
        margin-left:10px;
    }

    .papercactus__drone-image-container img {
        width:250px;
        height:250px;
    }

    .papercactus__drone-leftMarginFlipped {
          display:none;
    }

    .papercactus__drone-deckDemo {
        height:100%;
        width: 85vw;
        object-fit: contain;
    }
}

@media screen and (max-width: 650px) {
    .papercactus__drone-row-container {
        width:100% !important;
        flex-wrap: wrap;
    }

    .papercactus__drone-paragraph-column {
        width:80vw;
        align-items: center;
        text-align: center;
        margin-left: 0px;
    }

    .papercactus__drone-title-backing {
      text-align: center;
      display:flex;
      flex-direction: row;
      width: auto;
    }

    .papercactus__drone-header-image {
      right:50%;
      left: 50%;
      transform: translate( -50%);
    }

    .papercactus__drone-leftMarginFlipped {
        display:none;
    }

    .papercactus__drone-image-container {
        display:none;
    }

    .papercactus__drone-paragraph {
      padding-top:10px;
    }
}

@media screen and (max-width: 450px) {
    .papercactus__drone-paragraph-column p {
        font-size: 1.4rem;
    }

    .papercactus__drone-paragraph-column h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .papercactus__drone-title-backing {
        width: auto;
        text-align: center;
    }

    .papercactus__drone-title-backing h1 {
        font-size: 2.1rem !important;
    }
}

@media screen and (max-width: 340px) {
    .papercactus__drone-title-backing {
        width: 300px;
    }

    .papercactus__drone-title-backing h1 {
        font-size: 1.8rem !important;
    }
}