.papercactus__mail_backgroundColor {
    background: rgb(57,57,59);
    background: rgba(57,57,59,1); 
    position:relative;
}

.papercactus__CTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding: 2rem;
    color: #fff;
    padding-top:45px !important;
    padding-bottom:45px !important;
}

.papercactus__CTA_preview_images {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    top:20px;
    position: relative;
    height: 530px;
}

.papercactus__CTA_preview_images img {
    width: 177px;
}

.papercactus__CTA_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left:50px;
    padding-right:50px;
}

.papercactus__CTA-leftMargin {
    display:flex;
    position: relative;
    height:600px;
    left: 5px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    z-index:1;
}

.papercactus__CTA-rightMargin {
    display:flex;
    position: relative;
    height:600px;
    justify-content: center;
    flex-wrap: wrap;
    right: 5px;
    align-items: flex-end;
    z-index: 1;
}

.division__CTA_panel_container {
    position:relative;
    z-index: 4;
    display: flex;  
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:530px;
    width:800px;
    top: 20px;
    padding-left:25px;
    padding-right: 25px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.papercactus__CTA-smalltext {
    font-size: 0.9rem !important;
    padding-top:10px;
    padding-bottom:15px;
    width:400px;
    line-break: strict;
    margin:auto;
}



.division__CTA {
    font-family: var(--font-family2);
    font-size: 1.4rem;
    text-align: center;
    width:100%;
}

.division__CTA h1 {
    font-family: var(--font-family);
    font-size:2.5rem;  
}

.division__CTA a {
    color:#8ab7ff;
    font-weight:600;
}

.division__CTA a:hover {
    color:#fff
}

.division__CTA-discord {
    padding-top:0px;
}

.division__CTA-discord p {
    font-size: 1.9rem;
}

.division__CTA-mailchimp {
    padding-top:20px;
}

.mc__form h3 {
    font-size: 1.6rem !important;
    font-family: var(--font-family2) !important;
    font-weight: 400;
}

.mc__form {
    white-space:pre-wrap;
}

.mc__field-container {
    margin:auto;
    width:50%;
    padding-bottom:10px;
}

.mc__field-container-textfield {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 3px solid #fff;
    color: currentColor;
    background-color: #5c5c5c;
    border-radius: var(--size-radius);
    position: relative;
    height:1.8rem;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
    padding-left:5px;
    padding-right:5px;
}

.mc__field-container-textfield:focus {
    outline: none;
    background-color:#8ca0a3;
    transition: background-color 0.1s ease-out;
}

.mc__field-container-textfield__label {
          text-align: left;
          line-height: 1.2;
          font-size: 1.2rem;
        }

.mc__field-container-subscribe {
    background-color:#8ab7ff;
    border-radius: 8px;
    border-style: none;
    color: #FFFFFF;
    font-family: var(--font-family2);
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 400;
    height: 2rem;
    padding: 0 1.6rem;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    touch-action: manipulation;
}

.mc__field-container-subscribe:hover {
  background-color: #5B5B66;
  transition-duration: .1s;
}

.mc__field-container-subscribe:disabled {
    background-color:#a8a8a8;
    color:#575757;
    cursor:default;
}

.message-spacer {
    height:32px;
}


.loader {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #8ab7ff #8ab7ff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 16px;
    height: 16px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

@media screen and (max-width:1350px) {
    .papercactus__CTA_container {
        padding-left:10px;
        padding-right:10px;
    }

    .division__CTA_panel_container {
        width:600px;
    }
}

@media screen and (max-width:1050px) {
    .papercactus__CTA_preview_images {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        top:20px;
        position: relative;
        height: 300px;
    }
    
    .papercactus__CTA_preview_images img {
        width: 100px;
    }

    .message-spacer {
        height:5px;
    }

    .papercactus__CTA-leftMargin {
        height:350px;
    }

    .papercactus__CTA-rightMargin {
        height:350px;
    }
}

@media screen and (max-width:850px) {
    .division__CTA_panel_container {
        height:450px;
        width:450px;
    }

    .division__CTA p {
        font-size: 1.1rem;
    }

}

@media screen and (max-width:700px) {
    .division__CTA_panel_container {
        height:450px;
        width:100vw;
    }

    .division__CTA p {
        font-size: 1.1rem;
    }

    .papercactus__CTA {
        padding-top: 10px !important;
    }
    

    .papercactus__CTA-smalltext {
        width: 450px;
        padding-bottom:1px;
    }

    .division__CTA-discord {
        padding-top: 5px;
    }

    .division__CTA-discord p {
        font-size: 1.5rem;
    }
    
    .mc__field-container {
        width:250px;
    }

    .papercactus__CTA-leftMargin {
        height:470px;
    }

    .papercactus__CTA-rightMargin {
        height:470px;
    }

    #leftbanner {
        display:none;
    }
    
    #rightbanner {
        display:none;
    }

}


@media screen and (max-width:600px) {
    .division__CTA_panel_container {
        height:500px;
        width:100vw;
        top:0px;
    }
    .papercactus__CTA {
        padding-top:0px !important;
        padding-bottom:0px !important;
    }

    .papercactus__CTA-leftMargin {
        display:none;
    }

    .papercactus__CTA-rightMargin {
        display:none;
    }

    .mc__field-container-subscribe {
        width:200px;
        height:50px;
    }
}

@media screen and (max-width:500px) {
    .papercactus__CTA-smalltext {
        width: 300px;
        padding-bottom:1px;
    }

    .mc__field-container-textfield {
        height:2.0rem !important;
    }

    .division__CTA_panel_container {
        height:550px;
        top:0px;
    }

}

@media screen and (max-width:450px) {
    .division__CTA h1 {
        font-size: 2.2rem;
    }

    .division__CTA_panel_container {
        height:600px;
    }
}
