.AboutUs {
    background-color: #222;
}

.aboutus__general__text-title {
    display:flex;
    flex-direction: column;
    align-items:flex-end;
}

.aboutus__general__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 130px;
}

.page__header-title {
    z-index: 3;
}

.aboutus__staff-container {
    padding-bottom: 70px;
    padding-top: 70px;
}

.aboutus__staff__text {
    font-family: var(--font-family);
    font-size: 1.6rem;
    padding-bottom:20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.aboutus__background__container {
    background-color: #fff;
    padding-bottom:20px;
}

.aboutus__staff-container-background {
    position: relative;
    top:75px;
    padding-bottom:0px;
    background-image: linear-gradient(#d1e0cc, #eaf7e6);
  }

  .aboutus__general__location {
    display: flex;
    align-items: center;
    justify-content: center; /* Add this line */
    flex-direction: row;
    padding-bottom: 15px;
    flex-wrap: wrap;
}

.aboutus__general__location img {
    width:550px;
    height:100%;
    padding:20px 50px 20px 50px;
}

.aboutus__location_text {
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    font-size: 1.4rem;
}

.aboutus__location_text a {
    color:steelblue;
}

.aboutus__location_text a:hover {
    text-decoration: underline;
}

.aboutus__staff img {
    width:150px;
    margin-right:10px;
    margin-left:10px;
}


.aboutus__staff {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.aboutus__staff h3 {
    font-family: var(--font-family2);
    font-size: 1.2em;
    text-align: center;
}

.aboutus__staff p {
    font-family: var(--font-family2);
    font-size: 1.05rem;
    text-align: center;
}

.aboutus__staff a {
    font-family: var(--font-family2);
    font-size: 1.1rem;
    text-align: center;
    color:steelblue;   
}

.aboutus__staff a:hover {
    color:darkslateblue;
}

.aboutus__general {
    display:flex;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.aboutus__general::before {
    content: "";
    position: absolute;
    width: 100%;
    height:50%;
    background-color: rgba(255, 255, 255, 0.4);
    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
    z-index: -1;
  }

.aboutus__general_background {
    background-color: #fff;
    height: 200px;
}

.aboutus__general a {
    color:steelblue;
}


.aboutus__general a:hover {
    color:darkslateblue;
}


.aboutus__general__text-design {
    background-color: #fff;
    clip-path: polygon(79% 0, 100% 0%, 17% 100%, 0% 100%);
    /* Adjust the width and height as needed */
    width: 100px;
    height: 325px;
    /* Adjust the margin as needed to create the overlap */
    margin-left: -50px;
  }

.aboutus__general__text p {
    width: 100%; /* Adjust this value as needed */
    text-align: left;
  }

.aboutus__general__container h1 {
    font-family: var(--font-family);
    font-size: 1.8rem;
    letter-spacing: 0.05rem;
}

.aboutus__general__container p {
    font-size: 1.3rem;
    font-family: var(--font-family2);
}

.aboutus__general p {
    font-family: var(--font-family2);
    font-size: 1.7rem;
    line-height: 30px;
    color: #fff;
    text-align: end;
    padding-bottom:10px;
    padding:25px;
    border-radius: 1rem;
    width:700px;
}

.aboutus__general img {
    width:250px;
}

.section__aboutusgeneral__padding {
    padding: 3rem 9rem;
}

.aboutus__black__divider {
    background: #222;
    width:100vw;
    height:5px;
}

.aboutus__socials-mobile-container {
    display: none;
}

.aboutus__staff__column {
    display:flex;
    position: relative;
    padding:1px;
    padding-left:20px;
    padding-right:20px;
}

.aboutus__staff__card {
    position: relative;
    overflow: hidden;
    width:200px;
    height:295px;
    flex-direction: column;
    align-items:center;
    margin-left:20px;
    margin-right:20px;
    margin-bottom:20px;
    padding-bottom:15px;
    padding-top:15px;
    text-align: center;
    background-color: #fff;
    border-radius: 1rem;
    z-index: 2;
    border: 2px solid #000;
    transition: transform 0.1s;
    transform-style: preserve-3d;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.35);
}

.aboutus__staff__card h3 {
    font-weight: 500;
}

.card-back {
    position: absolute;
    top:0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #40945b, #8ee370);
    transform: rotateY(180deg);
    z-index: 2;
    visibility: hidden;
    display: flex;
    justify-content: center;
    padding-top:3.5%;
}

.card-back-border {
    position:absolute;
    box-sizing: border-box;
    border: solid 5px #ffffff;
    width: 95%;
    height: 95%;
    border-radius: 10px;
  }
  
  
  .papercactus__aboutus-card-back-image {
    height:170px;
    width: 100%;
    padding-top:20px;
    padding-right:5px;
}

.card-with-triangle::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-bottom: 40px solid rgba(0, 100, 0, 0.5);
  }
  

  .triangle-text {
    position: absolute;
    right: 0px;
    bottom: 13px;
    transform: rotate(-45deg);
    transform-origin: bottom right;
    color: white;
    z-index: 1;
    cursor: pointer;
    font-size: 0.9rem;
    font-family: var(--font-family2);
  }

.aboutus__staff__card img {
  position: relative;
  left: 40%;
  transform: translateX(-50%);
}

.aboutus__staff__card::before {
    content: "";
    position: absolute;
    width: 340%;
    height: 170%;
    background: linear-gradient(
      transparent, 
      rgba(255, 105, 180, 0.1) 10%,
      rgba(255, 105, 180, 0.2) 15%, 
      rgba(255, 140, 0, 0.1) 25%, 
      rgba(255, 140, 0, 0.2) 30%, 
      rgba(255, 255, 51, 0.1) 40%, 
      rgba(255, 255, 51, 0.2) 45%, 
      rgba(152, 251, 152, 0.1) 55%, 
      rgba(152, 251, 152, 0.2) 60%, 
      rgba(100, 149, 237, 0.1) 70%, 
      rgba(100, 149, 237, 0.2) 75%, 
      rgba(186, 85, 211, 0.1) 85%, 
      rgba(186, 85, 211, 0.2) 90%, 
      rgba(186, 85, 211, 0.1) 95%, 
      transparent);
    background-attachment: fixed;
    background-position: var(--mouse-x, 0) var(--mouse-y, 0);
    transform: rotate(-30deg) translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

.aboutus__staff__card.shine::before {
  opacity: 1;
}
  
  @keyframes shine {
    0% { transform: rotate(-30deg) translate(-50%, -50%); }
    100% { transform: rotate(-30deg) translate(150%, 150%); }
  }


.aboutus__staff__column_back-card {
    border-radius: 1rem;
    z-index: 1;
    width:200px;
    height:295px;
    transform: rotate(-5deg);
    position: absolute;
    top:-2px;
    transition: transform 0.3s ease-in-out;
}

  
  .aboutus__staff__column:hover .aboutus__staff__column_back-card,
  .aboutus__staff__column:hover .aboutus__staff__column_back-card-right {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .aboutus__staff__column_back-card-right {
    border-radius: 1rem;
    z-index: 0;
    width:200px;
    height:295px;
    transform: rotate(5deg);
    position: absolute;
    top:-2px;
    transition: transform 0.3s ease-in-out;
  }

@media screen and (max-width: 990px) {
    .page__header-title {
        width:650px !important;
    }

    .aboutus__general__text {
        width: 450px;
        align-items: center;
    }

    .aboutus__general__text p {
        width: 450px;
        text-align: left;
        font-size: 1.3rem;
    }

    .aboutus__staff-container {
        padding: 1rem 1rem;
    }

    .aboutus__photos-container img {
        width:450px;
        height:100%;
        padding:0px 50px 0px 50px;
    }

    .aboutus__general__location {
        
    }
    
}

@media screen and (max-width: 760px) {
    .aboutus__general__text {   
        width: 350px;
        align-items: center;
    }

    .aboutus__general__text p {
        width: 350px;
        text-align: left;
    }

    .aboutus__photos-container {
        flex-direction: column;
        min-width: 0px;
    }

    .aboutus__photos-container img {
        width:550px;
        height:100%;
    }
    
    #picture1 {
        padding-bottom:20px;
    }

    .aboutus__general::before {
        content: "";
        position: absolute;
        width: 100%;
        height:75%;
        background-color: rgba(255, 255, 255, 0.4);
        clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
        z-index: -1;
      }

}

@media screen and (max-width: 650px) {
    .aboutus__general {
        flex-direction: column;
        padding-top: 5px;
    }

    .aboutus__general__text {
        width: 350px;
        align-items: center;
    }

    .aboutus__general__text p {
        width: 500px;
        text-align: left;
    }

    .aboutus__general__text h1 {
        text-align: center;
    }

    .aboutus__general img {
        width: 200px;
    }

 
    .aboutus__staff__column a {
        margin-top:auto;
    }

    .aboutus__staff__column img {
        width:100px;
    }

    .aboutus__staff-container {
        padding: 1rem 1rem;
    }

    .aboutus__header {
        display:none;
    }

    .aboutus__staff__card {
        width:150px;
        height:250px;
        display:flex;
        flex-direction: column;
        align-items:center;
        margin-left:10px;
        margin-right:10px;
        margin-bottom:10px;
        padding-bottom:15px;
        padding-top:15px;
        text-align: center;
        background-color: #fff;
        border-radius: 1rem;
        transform: rotateX(var(--rotationX)) rotateY(var(--rotationY));
        transition: transform 0.3s ease-out;
    }

    .aboutus__staff__column_back-card {
        border-radius: 1rem;
        z-index: 1;
        width:150px;
        height:250px;
        rotate: -5deg;
        position: absolute;
        top:-2px;
    }

    .aboutus__general::before {
        content: "";
        position: absolute;
        width: 550px;
        height:100%;
        background-color: rgba(255, 255, 255, 0.4);
        clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
        z-index: -1;
      }

      .aboutus__general__text {
        display: flex;
        position: relative;
        width: 80%;
        align-items: center;
        font-size: 3rem;
    }

    .aboutus__general__text p {
        font-size: 1.4rem;
    }

    .aboutus__general__location {
        flex-direction: column;
      }
    
      .aboutus__location_text {
        padding-top: 30px;
      }

      .aboutus__staff__card::before {
        transform: rotate(0deg) translate(-0%, -10%);
      }

}

@media screen and (max-width: 550px) {
    .aboutus__socials-mobile-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: #222222;
        color: #fff;
        font-family: var(--font-family);
    }

    .aboutus__socials-mobile-container h1 {
        font-size: 1.9rem;
        padding-top:15px;
    }

    .aboutus__socials-mobile {
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content: center;
        color:#8a8a8a;
        background-color: #222222;
        height: 70px;
        font-size:2.2em;
    }

    
    .aboutus__socials-mobile-social_icon {
        padding-left: 20px;
        padding-right: 20px;
    }

    .aboutus__socials-mobile-social_icon i:active {
        color: #fff;
        transition: 0.2s ease-in-out;
    }

    .aboutus__general__text-title {
        align-items: center;
    }

    .aboutus__staff {
        flex-direction: column;
    }

    .aboutus__staff__column {
        display:flex;
        position: relative;
        justify-content: center;
    }
    
    .aboutus__staff__card {
        position: relative;
        overflow: hidden;
        width:350px;
        height:150px;
        display:flex;
        flex-direction: row;
        align-items:center;
        margin-left:10px;
        margin-right:10px;
        margin-bottom:20px;
        padding-bottom:15px;
        padding-top:15px;
        text-align: center;
        background-color: #fff;
        border-radius: 1rem;
        transform: rotateX(var(--rotationX)) rotateY(var(--rotationY));
        transition: transform 0.3s ease-out;
        transform-style: preserve-3d;
    }

    .aboutus__staff__card img {
        width:120px;
    }

    #staff_info {
        margin: 0px auto;
    }

    .aboutus__staff__column_back-card {
        border-radius: 1rem;
        z-index: 1;
        width:350px;
        height:150px;
        rotate: -5deg;
        position: absolute;
        top:-2px;
    }

    .aboutus__general__text {
        align-items: center;
    }

    .aboutus__general__text p {
        width: 400px;
        text-align: left;
        font-size: 1.5rem;
    }

    
    .aboutus__photos-container img {
        width:44  0px;
        height:100%;
    }

    .aboutus__general::before {
        content: "";
        position: absolute;
        width: 100%;
        height:100%;
        background-color: rgba(255, 255, 255, 0.4);
        clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
        z-index: -1;
      }
    
      .aboutus__general {
        padding-top: 50px;
      }

      .triangle-text {
        display: none;
      }

      .aboutus__staff__card img {
        position: relative;
        left:70px;
      }

      .aboutus__staff__card {
        width:85%;
      }    
}

@media screen and (max-width: 450px) {
    .aboutus__general__text p {
        width: 350px;
        text-align: left;
        font-size: 1.25rem;
    }

    .aboutus__general__text {
        width: 350px;
        align-items: center;
    }
}

