.papercactus__legal-general-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.papercactus__legal {
    max-width: 1100px;
    padding-bottom:25px;
}

.papercactus__legal h1 {
    font-family: var(--font-family);
    font-size: 28px;
}
.papercactus__legal p {
    font-family: var(--font-family2);
    font-size: 1.2rem;
}
  