.papercactusgames__social-container {
    display:flex;
    flex-direction: column;
    align-items: center;
}

.papercactusgames__social-background-gradient {
    position: relative;
    background: rgb(153,215,217);
    background: linear-gradient(90deg, rgba(153,215,217,1) 0%, rgba(68,181,228,1) 47%, rgba(0,88,147,1) 100%); 
    border-radius: 50px;
    padding: 5px;
    z-index: 0;
}

.papercactusgames__social-head-title {
    background-color: #222;
    color: #fff;
    font-size: 1rem;
    letter-spacing: 3px;
    z-index: 100;
    position: relative;
    font-family: var(--font-family);
    top:30px;
    right:370px;
    padding-left:20px;
    padding-right:50px;
    
}

.papercactusgames__social {
    position: relative;
    font-family: var(--font-family);
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:#8a8a8a;
    background-color: #222;
    border-radius: 50px;
    z-index: 1;
    height: 70px;
    width:75vw;
    max-width: 950px;
    font-size:2.0em;
    z-index: 999;
    padding-left: 50px;
    padding-right: 5px;
    padding-top: 50px;
    padding-bottom:50px;
}

.papercactus__socials-social_link {
    width:195px;
}

.papercactus__socials-social_link:hover {
    color: #fff;
    transition: 0.1s ease-in-out;
    filter: drop-shadow(0 1px 4px);
}

.papercactusgames__social a {
    width:195px;
}

.papercactus__socials-social_link {
    transition: 0.2s ease-in-out;
}
.papercactus__socials-social_icon-disable {
    width:195px;
}

.papercactus__socials-social_icon-disable a {
    color:#303030;
    font-family: var(--font-family);
}

@media screen and (max-width:1200px) {
    .papercactusgames__social-head-title {
        right:0px;
        padding-right:20px;
    }

    .papercactusgames__social {
        font-size:1.8em;
    }
}

@media screen and (max-width:1100px) {
    .papercactusgames__social {
        font-size:2.2em;
        flex-wrap: wrap;
        justify-content: center; /* Centers items horizontally */
        align-items: center;
        height:175px;
    }
    .papercactus__socials-social_link {
        padding-bottom:15px;
        width:185px;
    }
    .papercactus__socials-social_icon-disable {
        padding-bottom:15px;
    }
}


@media screen and (max-width:800px) {
    .papercactusgames__social {
        width: 580px;
        height:250px;
    }
}

@media screen and (max-width:650px) {
    .papercactusgames__social {
        width: 480px;
        height:200px;
        font-size: 2.2rem;
        padding-top:30px;
        padding-bottom:190px;
    }

    .papercactusgames__social a {
        padding-bottom:2px;
        padding-top:2px;   
    }
}

@media screen and (max-width: 550px) {
    .papercactusgames__social-container {
        padding-bottom:50px;
    }
}

@media screen and (max-width:500px) {
    .papercactusgames__social-container {
        margin-bottom: 0px !important;
    }

    .papercactusgames__social {
        width: 300px;
        height:330px;
    }
}