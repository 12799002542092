.papercactus__news {
    margin-top:50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Add this line */
    color: #fff;
    background: #4588aa;
    clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
    max-width: 1200px;
}
.papercactus__news::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    background: #222;
    clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
    z-index: -1;
}

.papercactus__news_backgroundColor {
    background-color: #222222 !important;
    position:relative;
    justify-content: center;
    display:flex;
    padding-bottom:10px;
    
}

.papercactus__news-title-backing {
    position: relative;
    justify-content: center;
    z-index: 2;
}

.papercactus__news_section {
    text-align: center;
    z-index: 2;

}

.papercactus__news_section h1 {
    font-family: var(--font-family);
    font-size:2.9rem;
}

.papercactus__news_section p {
    font-size: 1.6rem;
    font-family: var(--font-family2);
}

.papercactus__news-picture-container {
    position: relative;
}

.news-image-container {
    position: relative;
    width: 300px;
    height: 300px; 
    padding-left: 10px;
}

.news-image-container-mask {
    width: 300px;
    height: 390px; 

    position: relative;
    clip-path: inset(-50px 13px 0px -30px); 
}

.news-backdrop-image {
    position: absolute;
    top: -20px;
    left: -10px;
}

.news-drone1-animate {
    animation: scaleDrone 1.1s forwards, hoverDrone 2s 1.2s infinite;
}

.news-drone1 {
    position: absolute;
    top: -50px;
    transform: scale(1.0) translateX(0px) translateY(0px);
}

.news-image-container_mobile {
    display:none;
}

.papercactus__news-kickstarter {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

.kickstarter-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.countdown-timer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 3.0em;
    color: white;
    text-align: center;
    font-family: var(--font-family2);
}

@keyframes scaleDrone {
    0% {
        transform: scale(0.2) translateX(0px) translateY(0px);
    }
    100% {
        transform: scale(1.0) translateX(-315px) translateY(50px);
    }
}

@keyframes hoverDrone {
    0%, 100% {
        transform: scale(1.0) translateX(-315px) translateY(50px);
    }
    50% {
        transform: scale(1.0) translateX(-315px) translateY(46px);
    }
}

@media screen and (max-width: 750px) {
    .papercactus__news-title-backing {
        display: flex;
        align-items: center;
    }

    .papercactus__news {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .papercactus__news_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:80%;
    }

    .news-image-container {
        display:contents;
    }

    .papercactus__news_section h1 {
        font-family: var(--font-family);
        font-size:2.0rem;
    }
    
    .papercactus__news_section p {
        font-size: 1.2rem;
        font-family: var(--font-family2);
    }

    .news-image-container {
        display:none;
    }

    .news-image-container_mobile {
        display:inline;
    }
    
}

@media screen and (max-width: 650px) {

    
}