@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&display=swap');



:root {
  --font-family: 'Bebas Neue', cursive;
  --font-family2: 'Barlow Condensed', sans-serif;
  --font-display: 'swap';
  font-weight: 300;

  --gradient-bar: linear-gradient(90deg, rgba(68,84,101,1) 26%, rgba(21,56,64,1) 100%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

h1 {
  font-weight: 400;
}