.papercactus__whatFoxNShadow {
    display: flex;
    flex-direction: row;
    position:relative;
    color: #fff;
    padding-top:70px !important;
    padding-bottom:120px !important;
    background: rgb(68,84,101);
    background: linear-gradient(45deg, rgba(68,84,101,1) 0%, rgba(57,57,59,1) 70%); 
    clip-path: polygon(
        0% 0%,
        100% 10%,
        100% 80%,
        0% 100%
      );
      max-width: 1500px;
}

.papercactus__whatFoxNShadow_backgroundColor {
    background-color: #222222 !important;
    position:relative;
    justify-content: center;
    display:flex;
}

.papercactus__whatFoxNShadow-header-image {
    position:absolute;
    z-index:-1;
    width:250px;
    top:-35px;
    left:-20px;
}

.papercactus__whatFoxNShadow-world {
    display: flex;
    flex-direction:column;
    max-width:800px;
    justify-content: center;
    margin-right:50px;
    z-index:1;
}

.papercactus__whatFoxNshadow-leftMargin {
    display:flex;
    width:38px;
    justify-content: center;
    margin-right:20px;
    flex-wrap: wrap;
    align-items: flex-start;
    z-index:1;
}

.papercactus__whatFoxNshadow-title-backing {
    width:200px;
    position: relative;
}

.papercactus__whatFoxNShadow-world h1 {
    font-family: var(--font-family);
    font-size:2.5rem;  
}

.papercactus__whatFoxNShadow-world p {
    font-size: 1.6rem;
    font-family: var(--font-family2);
}

.papercactus__whatFoxNShadow-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    
}

.papercactus__whatFoxNShadow-container img {
    width:300px;
    height:300px;
}

.papercactus__whatFoxNShadow-container {
    margin-top: 0.5rem;
}

.papercactus__whatFoxNShadow-paragraph {
    padding-bottom:55px;
}

@media screen and (max-width: 900px) {
    .papercactus__whatFoxNShadow-world p {
        font-size: 1.5rem;
    }

    .papercactus__whatFoxNShadow-world {
        width:70vw;
        margin-right:10px;
        margin-left:30px;
    }

    .papercactus__whatFoxNShadow-container img {
        width:250px;
        height:250px;
    }

    .papercactus__whatFoxNshadow-leftMargin {
        display:none;
    }
    
    .papercactus__whatFoxNShadow-world {
        padding-left: 75px;
    }

    .papercactus__whatFoxNShadow-container {
        padding-right:100px;
    }
}

@media screen and (max-width: 700px) {
    .papercactus__whatFoxNShadow-world {
        padding-left: 100px;
    }

    .papercactus__whatFoxNShadow-container {
        padding-right:120px;
    }
}



@media screen and (max-width: 650px) {
    .papercactus__whatFoxNShadow {
        width:100% !important;
        flex-wrap: wrap;
        text-align: center;
        clip-path: polygon(
            0% 0%,
            100% 5%,
            100% 90%,
            0% 100%
          );
    }

    .papercactus__whatFoxNShadow-world {
        display: flex;
        flex-direction:column;
        padding-left: 0px;
        align-items: center !important;
        margin: 20px auto;
        z-index:1;
        width:80vw;
    }

    .papercactus__whatFoxNshadow-title-backing {
        justify-content: flex-start;
        display:flex;
        flex-direction: row;
        width: auto;
    }

    .papercactus__whatFoxNshadow-leftMargin {
        display:none;
    }

    .papercactus__whatFoxNShadow-container {
        margin: 0px auto;
        padding-right:0px;
    }


    .papercactus__whatFoxNShadow-header-image {
        left: -52px;
        top:-35px;
    }

    .papercactus__whatFoxNShadow-paragraph {
        padding-top: 10px;
        width:90vw;
    }
}


@media screen and (max-width: 450px) {
    .papercactus__whatFoxNShadow-world p {
        font-size: 1.4rem;
    }

    .papercactus__whatFoxNShadow-world h1 {
        font-size: 2.5rem;
    }

    .papercactus__whatFoxNShadow-header-image {
        left:-55px;
        top: -35px;
    }
}
