* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.papercactusgames__baseBackground {
  background-color: #fff;
}

body {
    background: var(--color-bg);
    overflow-x: hidden;
    background-color: #222222;
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 6rem 6rem;
}

.section__small__padding {
    padding: 3rem 6rem;
}

.section__xtrasmall__padding {
    padding:1rem 6rem;
}

.section__pressKit__padding {
  padding-top: 25px;
  padding-left: 6rem;
  padding-right:6rem;
}

  
.papercactus__cyberpunk-hud {
  position: absolute;
  overflow: hidden;
  height:100%;
  width: 1600px;
  pointer-events: none;
}


@media screen and (max-width: 700px) {
      .section__xtrasmall__padding {
        padding: 1rem 2rem;
    }
}

@media screen and (max-width: 550px) {
  .section__small__padding {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 450px) {
  .section__small__padding {
    padding: 1rem 2rem;
  }
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


.hero {
    line-height: 1;
    display: inline-block;
    color: #fff;
    z-index: 2;
    letter-spacing: 2px;
  
    /* Bright things in dark environments usually cast that light, giving off a glow */
    filter: drop-shadow(0 1px 3px);
  }
   
  .layers {
    position: relative;
  }
  
  .layers::before,
  .layers::after {
    content: attr(data-text);
    position: absolute;
    width: 110%;
    z-index: -1;
  }
  
  .layers::before {
    top: 10px;
    left: 15px;
    color: #e0287d;
  }
  
  .layers::after {
    top: 5px;
    left: -10px;
    color: #1bc7fb;
  }
  
  .single-path {
    clip-path: polygon(
      0% 12%,
      53% 12%,
      53% 26%,
      25% 26%,
      25% 86%,
      31% 86%,
      31% 0%,
      53% 0%,
      53% 84%,
      92% 84%,
      92% 82%,
      70% 82%,
      70% 29%,
      78% 29%,
      78% 65%,
      69% 65%,
      69% 66%,
      77% 66%,
      77% 45%,
      85% 45%,
      85% 26%,
      97% 26%,
      97% 28%,
      84% 28%,
      84% 34%,
      54% 34%,
      54% 89%,
      30% 89%,
      30% 58%,
      83% 58%,
      83% 5%,
      68% 5%,
      68% 36%,
      62% 36%,
      62% 1%,
      12% 1%,
      12% 34%,
      60% 34%,
      60% 57%,
      98% 57%,
      98% 83%,
      1% 83%,
      1% 53%,
      91% 53%,
      91% 84%,
      8% 84%,
      8% 83%,
      4% 83%
    );
  }
  
  .paths {
    animation: paths 5s step-end infinite;
  }
  
  @keyframes paths {
    0% {
      clip-path: polygon(
        0% 43%,
        83% 43%,
        83% 22%,
        23% 22%,
        23% 24%,
        91% 24%,
        91% 26%,
        18% 26%,
        18% 83%,
        29% 83%,
        29% 17%,
        41% 17%,
        41% 39%,
        18% 39%,
        18% 82%,
        54% 82%,
        54% 88%,
        19% 88%,
        19% 4%,
        39% 4%,
        39% 14%,
        76% 14%,
        76% 52%,
        23% 52%,
        23% 35%,
        19% 35%,
        19% 8%,
        36% 8%,
        36% 31%,
        73% 31%,
        73% 16%,
        1% 16%,
        1% 56%,
        50% 56%,
        50% 8%
      );
    }
  
    5% {
      clip-path: polygon(
        0% 29%,
        44% 29%,
        44% 83%,
        94% 83%,
        94% 56%,
        11% 56%,
        11% 64%,
        94% 64%,
        94% 70%,
        88% 70%,
        88% 32%,
        18% 32%,
        18% 96%,
        10% 96%,
        10% 62%,
        9% 62%,
        9% 84%,
        68% 84%,
        68% 50%,
        52% 50%,
        52% 55%,
        35% 55%,
        35% 87%,
        25% 87%,
        25% 39%,
        15% 39%,
        15% 88%,
        52% 88%
      );
    }
  
    30% {
      clip-path: polygon(
        0% 53%,
        93% 53%,
        93% 62%,
        68% 62%,
        68% 37%,
        97% 37%,
        97% 89%,
        13% 89%,
        13% 45%,
        51% 45%,
        51% 88%,
        17% 88%,
        17% 54%,
        81% 54%,
        81% 75%,
        79% 75%,
        79% 76%,
        38% 76%,
        38% 28%,
        61% 28%,
        61% 12%,
        55% 12%,
        55% 62%,
        68% 62%,
        68% 51%,
        0% 51%,
        0% 92%,
        63% 92%,
        63% 4%,
        65% 4%
      );
    }
  
    45% {
      clip-path: polygon(
        0% 33%,
        2% 33%,
        2% 69%,
        58% 69%,
        58% 94%,
        55% 94%,
        55% 25%,
        33% 25%,
        33% 85%,
        16% 85%,
        16% 19%,
        5% 19%,
        5% 20%,
        79% 20%,
        79% 96%,
        93% 96%,
        93% 50%,
        5% 50%,
        5% 74%,
        55% 74%,
        55% 57%,
        96% 57%,
        96% 59%,
        87% 59%,
        87% 65%,
        82% 65%,
        82% 39%,
        63% 39%,
        63% 92%,
        4% 92%,
        4% 36%,
        24% 36%,
        24% 70%,
        1% 70%,
        1% 43%,
        15% 43%,
        15% 28%,
        23% 28%,
        23% 71%,
        90% 71%,
        90% 86%,
        97% 86%,
        97% 1%,
        60% 1%,
        60% 67%,
        71% 67%,
        71% 91%,
        17% 91%,
        17% 14%,
        39% 14%,
        39% 30%,
        58% 30%,
        58% 11%,
        52% 11%,
        52% 83%,
        68% 83%
      );
    }
  
    76% {
      clip-path: polygon(
        0% 26%,
        15% 26%,
        15% 73%,
        72% 73%,
        72% 70%,
        77% 70%,
        77% 75%,
        8% 75%,
        8% 42%,
        4% 42%,
        4% 61%,
        17% 61%,
        17% 12%,
        26% 12%,
        26% 63%,
        73% 63%,
        73% 43%,
        90% 43%,
        90% 67%,
        50% 67%,
        50% 41%,
        42% 41%,
        42% 46%,
        50% 46%,
        50% 84%,
        96% 84%,
        96% 78%,
        49% 78%,
        49% 25%,
        63% 25%,
        63% 14%
      );
    }
  
    90% {
      clip-path: polygon(
        0% 41%,
        13% 41%,
        13% 6%,
        87% 6%,
        87% 93%,
        10% 93%,
        10% 13%,
        89% 13%,
        89% 6%,
        3% 6%,
        3% 8%,
        16% 8%,
        16% 79%,
        0% 79%,
        0% 99%,
        92% 99%,
        92% 90%,
        5% 90%,
        5% 60%,
        0% 60%,
        0% 48%,
        89% 48%,
        89% 13%,
        80% 13%,
        80% 43%,
        95% 43%,
        95% 19%,
        80% 19%,
        80% 85%,
        38% 85%,
        38% 62%
      );
    }
  
    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
      clip-path: none;
    }
  }
  
  .movement {
    /* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
    position: relative;
    animation: movement 8s step-end infinite;
  }
  
  @keyframes movement {
    0% {
      top: 0px;
      left: -20px;
    }
  
    15% {
      top: 10px;
      left: 10px;
    }
  
    60% {
      top: 5px;
      left: -10px;
    }
  
    75% {
      top: -5px;
      left: 20px;
    }
  
    100% {
      top: 10px;
      left: 5px;
    }
  }
  
  .opacity {
    animation: opacity 5s step-end infinite;
  }
  
  @keyframes opacity {
    0% {
      opacity: 0.1;
    }
  
    5% {
      opacity: 0.7;
    }
  
    30% {
      opacity: 0.4;
    }
  
    45% {
      opacity: 0.6;
    }
  
    76% {
      opacity: 0.4;
    }
  
    90% {
      opacity: 0.8;
    }
  
    1%,
    7%,
    33%,
    47%,
    78%,
    93% {
      opacity: 0;
    }
  }
  
  .font {
    animation: font 7s step-end infinite;
  }
  
  @keyframes font {
    0% {
      font-weight: 100;
      color: #e0287d;
      filter: blur(3px);
    }
  
    20% {
      font-weight: 500;
      color: #fff;
      filter: blur(0);
    }
  
    50% {
      font-weight: 300;
      color: #1bc7fb;
      filter: blur(2px);
    }
  
    60% {
      font-weight: 700;
      color: #fff;
      filter: blur(0);
    }
  
    90% {
      font-weight: 500;
      color: #e0287d;
      filter: blur(6px);
    }
  }
  
  .glitch span {
    animation: paths 5s step-end infinite;
  }
  
  .glitch::before {
    animation: paths 5s step-end infinite, opacity 5s step-end infinite,
      font 8s step-end infinite, movement 10s step-end infinite;
  }
  
  .glitch::after {
    animation: paths 5s step-end infinite, opacity 5s step-end infinite,
      font 7s step-end infinite, movement 8s step-end infinite;
  }

  .page__header {
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
  }

.page__header img {
    width:100vw;
}

.page__header-title {
    position:absolute;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    width:900px;
    font-family: var(--font-family);
    font-size: 1.7rem;
    color: #fff;
    text-shadow: 6px 3px 3px rgba(0,0,0,0.65);
}

.page__header-title .page__general__divider {
    width:200px;
    position:absolute;
} 

.papercactus__page-image {
    height:150px;
    object-fit: cover;
}

.no-image-backcolor {
  background: rgb(136,167,97);
  background: linear-gradient(90deg, rgba(136,167,97,1) 0%, rgba(52,61,53,1) 100%);
  width:100%;
}

.page__general__divider {
  width: 200px;
  height: 5px;
  background: linear-gradient(103.22deg, #00b0b6 -13.86%, #d6db48 99.55%);
  box-shadow: 0 4px 4px rgba(0,0,0,0.25);
  margin-bottom: 0.25rem;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.play-animation-section {
  opacity: 0;
  visibility: hidden;
  will-change: opacity, visibility;
}

.play-animation-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.papercactus__CTA_panel_animation_gradiant_top {
  background: linear-gradient(to top, rgba(79, 211, 255, 0.301) 0%, rgba(173,216,230,0) 85%);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}


  
 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.division__CTA_panel_animation {
  animation-name:backgound-scroll-up-CTAV;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}

@keyframes backgound-scroll-up-CTAV {
  0%   {top:-5px;}
  99.99% {top:-45px;}
  100% {top:0px;}
}


@media screen and (max-width: 1500px) {
  .papercactus__cyberpunk-hud {
    display:none;
  }
}


@media screen and (max-width: 900px) {
  .section__padding {
    padding: 4rem 2rem;
  }
}

@media screen and (max-width:700px) {
      .section__padding {
        padding: 4rem 2rem;
      }
      .section__margin {
        padding: 4rem;
    }

    .page__header-title {
      width:520px !important;
    }
}

@media screen and (max-width:550px) {
  .section__padding {
    padding: 3rem 2rem;
  }
  .section__margin {
    padding: 4rem 2rem;
  }
    
  .page__header {
    display:none;
  }

  .overlay-content {
    flex-direction: column;
  }
  
  .page__header-title {
    position: relative;
    padding-top:10px;
    height:70px;
    align-items: center;
  }

  .page__general__divider {
    position: relative;
    
  }
}

@media screen and (max-width: 450px) {
  .page__header-title {
    padding-top: 10px;
    position: relative ;
    height:70px;
    align-items: center;
  }

  .page__general__divider {
    position: relative;
  }
}