.carousel {
  position: relative;
  width: 100%; /* 100% of the parent's width */
  height: 92vh; /* 100% of the viewport height */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-content {
  position: relative;
  /* Add your styles for the overlay content here */
  width:1200px;
  height:750px;
  display: flex;
  justify-content: center;
}

.page__header-title {
  position:absolute;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  width:900px;
  font-family: var(--font-family);
  font-size: 1.7rem;
  color: #fff;
  text-shadow: 6px 3px 3px rgba(0,0,0,0.65);
}

@media screen and (max-width: 450px) {
  .overlay-content {
    width:auto;
    display: initial;
  }
}