.papercactus__screenshots {
    display: flex;
    flex-direction: column;
    background-color: #222222;
    justify-content: center;
    position: relative; /* Ensure this is set */
    color: #fff;
    overflow:hidden;
}

.background-gradient-right {
    position: absolute;
    bottom: 0;
    left: 10%;
    width: 100%;
    height: 100%; /* Adjust as needed */
    background: radial-gradient(circle 500px at center bottom, rgba(0,0,255,0.2), transparent);
    z-index: 1;
}

.background-gradient-left{
    position: absolute;
    bottom: 0;
    left: -10%;
    width: 100%;
    height: 100%; /* Adjust as needed */
    background: radial-gradient(circle 550px at center bottom, rgba(29, 135, 255, 0.2), transparent);
    z-index: 1;
}

.papercactus__whatFoxNShadow-screenshots {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom:50px;
    z-index: 2;
}

.papercactus__choices {
    max-width:1000px;
}

.papercactus__screenshots-leftMargin__Yaxis {
    display:flex;
    height:300px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-end;
}

.papercactus__whatFoxNShadow-screenshots h1 {
    font-family: var(--font-family);
    font-size:2.5rem;
}

.papercactus__whatFoxNShadow-screenshots p {
    font-size: 1.6rem;
    font-family: var(--font-family2);
}

.papercactus__whatFoxNShadow-screenshots-title {
    width: 200px;
    height: 5px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.papercactus__screenshots-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:center;
    align-items: center;
    z-index: 2;

}

.papercactus__screenshots-container img {
    width:250px;
    height:230px;
    object-fit:contain;
    margin-bottom:5px;
    margin-left: 10px;
    margin-right: 10px;
    transform: scale(1);
    transition-duration: 0.3s;
}

.papercactus__screenshots-container img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition-duration: 0.3s;
}

.digitalDisplay3 {
    width:150px;
    position: absolute;
    top:200px;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
  
  .digitalDisplay3.left {
    left:100px;
  }


  .digitalDisplay3.right {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    right:100px;
  }

  .digitalDisplay3.animation {
    animation: dash 2s linear forwards;
  }

  .papercactus__choices-header-image {
    position: absolute;
    z-index: -1;
    width: 250px;
    top: 60px;
  }

@media screen and (max-width:1275px) {
    .papercactus__screenshots-container img {
        width:180px;
        height:200px;
        padding-bottom:5px;
        margin-left: 35px;
        margin-right: 35px;
    }
}

@media screen and (max-width:1200px) {
    .papercactus__screenshots-container img {
        width:230px;
        height:230px;
        padding-bottom:5px;
        margin-left: 35px;
        margin-right: 35px;
    }
}



@media screen and (max-width:900px) {
    .papercactus__choices-header-image {
        top:25px;
    }

    .papercactus__whatFoxNShadow-screenshots p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 850px) {
    .papercactus__whatFoxNShadow-screenshots p {
        margin-top: 10px;
    }
}

@media screen and (max-width: 750px) {
    .papercactus__screenshots-container img {
        width:200px;
        height:200px;
        padding-bottom:5px;
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media screen and (max-width: 550px) {
    .papercactus__screenshots-container img {
        width:170px;
        height:170px;
        padding-bottom:5px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .papercactus__choices-header-image {
        top:15px;
    }
}

@media screen and (max-width: 450px) {
    .papercactus__whatFoxNShadow-screenshots p {
        font-size: 1.4rem;
    }

    .papercactus__whatFoxNShadow-screenshots h1 {
        font-size: 2.5rem;
    }

    .papercactus__screenshots-container img {
        width:140px;
        height:140px;
        padding-bottom:5px;
        margin-left: 5px;
        margin-right: 5px; 
    }
}