.papercactusgames__notFound__container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
}

.notFound__logo {
    height:100%;
    width:250px;
}

.notFound-home {
    margin-top:50px;
}

.notFound-home a {
    color: darkslateblue;
    font-weight:600;
}

.notFound-home a:hover {
    color:rgb(68, 84, 101);
}