@import 'animation.css'; 

.papercactus__header {
    height: 1500px;
    background-color: #222;
}

.header-announcement {
    letter-spacing: 0.13em; /* Adjust as needed */
    font-size: 2.7em; /* Adjust as needed */
    text-shadow: none;
    color: white;
}

.papercactus__header-scroll-arrow-container {
    display:flex;
    justify-content: center;
    position: relative;
    top:560px;
    z-index: 1;
}

.papercactus__header-scroll-arrow-desktop-container {
    position: absolute;
    height:100px;
    width:60px;
}

.papercactus__header-scroll-arrow-desktop {
    position: absolute;
    height:100px;
    width:100%;
    animation-name: FnS-arrow-hover-1;
    animation-iteration-count: infinite;
    animation-duration: 2s;
}

.papercactus__header-scroll-arrow-desktop-overlay {
    position: absolute;
    height: 100px;
    width: 60px;
    opacity: 0;
    left:0px;
    top:1px;
    transition: .3s ease-in-out;
    background-image: url('../../assets/images/arrow-scroll-down-highlight2.png');
    background-repeat: no-repeat;
    background-size: 60px 100px;
    animation-name: FnS-arrow-hover-2;
    animation-iteration-count: infinite;
    animation-duration: 2s;
}

.papercactus__header-scroll-arrow-container:hover .papercactus__header-scroll-arrow-desktop-overlay {
    opacity: 1;
    cursor: pointer;
  }

.papercactus__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.papercactus__header-image img {
    width:50vw;
}

.papercactus__header-content {
    position:relative;
    height:80vh;
    width:40vw;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: #222222;
    opacity: 1;
    left:0px;
    z-index: 100;
}

.loader-container__background {
    width: 100%;
    height: 120vh;
    position:fixed;
    background-image: linear-gradient(0deg, #222222 50%, #303030 50%);
    background-size: 10px 10px;
    animation-name:backgound-scroll-up;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: fowards;
}
.hex {
    width: 300px;
    height: 300px;
    background-image: url("../../assets/images/Hexshape.svg");
    background-size:contain;
    background-repeat: no-repeat;
    position:relative;
    opacity: 0.4;
    left:-4px;
}

.spinner {
    width: 300px;
    height: 300px;
    position:absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);    
}

.spinner__center {
    width: 30px;
    height: 30px;
    border: 4px solid white;
    position:absolute;
    animation: spinner;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}

.spinner__left {
    position:absolute;
    background-color: #fff;
    width:5px;
    height:5px;
    left:75px;
    animation: spinner__left_animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.circle__left {
    border-radius: 25px 25px 25px 25px;
    border: solid 4px white;
    height:20px;
    width:20px;
    position:absolute;
    top:141.9px;
    left: 57.8px;
}

.spinner__right {
    position:absolute;
    background-color: #fff;
    width:5px;
    height:5px;
    right:75px;
    animation: spinner__right_animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.circle__right {
    border-radius: 25px 25px 25px 25px;
    border: solid 4px white;
    height:20px;
    width:20px;
    position:absolute;
    top:141.9px;
    right: 57.8px;
}

.header__02-5 {
    top:210px !important;
    height:200px !important;
}

.header__02-5_logo {
    height:280px;
}

.header__02-5_logo-mask {
    height:240px;
}

.header__h1 {
    top: 500px !important;
    position: relative !important;
}

.hide {
    animation: fadeout;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  .matrix {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    mask-image:radial-gradient(circle at 50% 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }
  
  .row {
    display: flex;
  }
  
  .cell {
    width: 25px;
    height: 25px;
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color:dodgerblue;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
  }
  


  @keyframes spinner__left_animation {
    0% {left:75px; top:148.5px;}
    10% {left: 75px; width:45px; top:148.5px;}
    20% {left:116px; width:5px;height:5px; top:148.5px;}
    30% {left:116px; height:35px; top: 118.5px;}
    40% {left:116px; height:5px; width: 5px; top: 118.5px;}
    50% {left:116px; height:5px; width:70px; top: 118.5px;}
    60% {left:185px; height:5px; width:5px; top: 118.5px;}
    70% {left:185px; height:35px; width:5px; top: 118.5px;}
    80% {left:185px; height:5px; width:5px; top: 148.5px;}
    90% {left:185px; height:5px; width:40px; top: 148.5px;}
    100% {left:220px; height:5px; width:5px; top: 148.5px;}
  }

  @keyframes spinner__right_animation {
    0% {right:75px; bottom:146.5px;}
    10% {right: 75px; width:43px; bottom:146.5px;}
    20% {right:114px; width:5px;height:5px; bottom:146.5px;}
    30% {right:114px; height:32px; bottom: 118.5px;}
    40% {right:114px; height:5px; width: 5px; bottom: 118.5px;}
    50% {right:114px; height:5px; width:70px; bottom: 118.5px;}
    60% {right:185px; height:5px; width:5px; bottom: 118.5px;}
    70% {right:185px; height:35px; width:5px; bottom: 118.5px;}
    80% {right:185px; height:5px; width:5px; bottom: 146.5px;}
    90% {right:185px; height:5px; width:40px; bottom: 146.5px;}
    100% {right:220px; height:5px; width:5px; bottom: 146.5px;}
  }

  @keyframes spinner {
    0% {transform: rotate(90deg);}
    33% {transform: rotate(90deg);}
    66% {transform: rotate(18   0deg);}
    100% {transform: rotate(180deg);}
  }
  
  @keyframes fadeout {
    from{opacity:1}to{opacity:0}
  }

  @keyframes backgound-scroll-up {
    0%   {top:0px;}
    99.99% {top:-20px;}
    100% {top:0px;}
}

.papercactus__header-mobile {
    display:none;
}

@media screen and (max-width: 900px) {

}

@media screen and (max-width:1000px) {
    .header__02-5 {
        left:0 !important;
    }

    .header__h1 {
        left:0 !important;
    }
}

@media screen and (max-width:450px) {
    .papercactus__header {
        display:none !important;
        object-fit: contain !important;
        width:450px !important;
        position: relative !important;
    }
    
    /*static page on mobile */
    
    .papercactus__header-mobile {
        display:inline !important;
    }

    .papercactus__header-mobile {
        height:650px;
        width:450px;
        float:right;
        top:133px;
        position: relative;
    }
    
    .papercactus__header-mobile-images {
        width:450px;
    }

    .papercactus__header-mobile-images-logo {
        position:absolute;  
        width:250px;
        height:250px;
        left:75px;
        top:100px;
    }

    .papercactus__header-mobile-images-end {
        position:relative;
        top:-120px;
    }
}

@media screen and (max-width:400px) {
    .papercactus__header-mobile-images-logo {
        width:250px;
        left:0;
        right:0;
        margin: 0 auto;
        top:100px;
    }
}

@media screen and (max-width:350px) {
    .papercactus__header-mobile-images-logo {
        width:250px;
        left: 100px;
        top:100px;
    }
}

