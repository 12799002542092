@keyframes FnS-arrow-hover-1 {
    0%   {top:0px;}
    50%  {top:5px;}
    100% {top:0px;}
  }

  @keyframes FnS-arrow-hover-2 {
    0%   {top:-0.4px;}
    50%  {top:4.4px;}
    100% {top:0px;}
  }