.papercactus__pressKit {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    max-width: 1400px;
}

.papercactus__information-side-navbar {
    width:500px;
    padding:25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: end;
    background-color: #eaf7e6;
}

.papercactus__information-side-navbar-nav {
    left:0px;
}

.papercactus__information-side-navbar-nav p {
    padding: 5px 10px 5px 0;
}

.papercactus__information-side-navbar-nav p:hover {
    background-color: rgb(129, 211, 161);
    border-radius: 3px;
    transition: 0.15s;
}

.papercactus__information-side-navbar-nav p {
    transition: 0.15s;
}

.papercactus__pressKit-general-container {
    display:flex;
    flex-direction: row;
    justify-content: center;
}


.papercactus__pressKit-general-container h1 {
    font-family: var(--font-family);
    font-size: 1.7rem;
    letter-spacing: 0.05rem;
}

.papercactus__pressKit-general-container p {
    font-size: 1.2rem;
    font-family: var(--font-family2);
}

.papercactus__information-videos {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.papercactus__information-promotional {
    margin-bottom: 50px;
}

.papercactus__information-contactTeam {
    display:flex;
    flex-direction: row;
    padding-top:10px;
}

.papercactus__information-contactTeam a {
    color:steelblue;
}

.papercactus__information-contactTeam a:hover {
    color:darkslateblue;
}

.papercactus__information-news {
    display: flex;
    flex-direction: row;
    padding-top:10px;
}

.papercactus__information-news a {
    color:steelblue;
}

.papercactus__information-news a:hover {
    color:darkslateblue;
}

.papercactus__information-news span {
    font-family: var(--font-family2);
    font-size: 1.2rem;
}

.news-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width:300px;
    padding-bottom:5px;
    transition: 0.3s;
    border-radius: 5px;
    background-color: #fff;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 5px;
}
.news-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
.news-card img {
    border-radius:5px 5px 0 0;
    object-fit:cover;
    width:300px;
    height:150px;
}  

.news-card-content {
    padding: 2px 16px;
}

.papercactus__information-logo {
    width:250px;
    height:250px;
    margin-bottom:10px;
}

.papercactus__whatFoxNShadow-information-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position:relative;
    padding-bottom:10px;
}

.papercactus__whatFoxNShadow-information {
    display: flex;
    flex-direction:column;
    margin-left:25px;
    position:relative;
}

.papercactus__information-text {
    margin-top:10px;
    border-radius: 10px 10px 10px 10px;
    padding:15px;
}

.papercactus__information-text p {
    padding-bottom:10px
}

.papercactus__information-header {
    display:flex;
    flex-direction: column;
    position:relative;
}

.papercactus__information-header h1{
    font-family: var(--font-family);
    font-size: 1.9rem;
    letter-spacing: 0.05rem;
}

.papercactus__information-header p {
    font-family: var(--font-family2);
    font-size: 1.6rem;

}

.papercactus__information-leftside {
    flex-direction: column;
    display: flex;
    align-items: center;
    width:250px;
    height: 100%;
    margin-right:20px;
}

.papercactus__information-leftside h1, .papercactus__information-leftside p {
    text-align: end;
}


.FnSpressKitGallery__style {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;

}

.FnSpressKitGallery__style img {
    width:200px;
    height:180px;
    object-fit:contain;
    margin-bottom:5px;
    margin:10px;
    border-radius: 5px 5px 5px 5px;
    transform: scale(1);
    transition-duration: 0.3s;
}

.FnSpressKitGallery__style img:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition-duration: 0.3s;
}

.papercactus__information-contactTeam-row2 {
    padding-left:100px;
}

.papercactus__information-contactTeam-row1 {
    padding-right:100px;
}

@media screen and (max-width: 1050px) {
 #navbar-divider {
    width: 100px;
    margin-left:100px;
 }
}

@media screen and (max-width: 870px) {
    .papercactus__information-side-navbar {
        display:none;
    }
   }

@media screen and (max-width: 650px) {
    .papercactus__whatFoxNShadow-information-container h1 {
        font-size: 1.4rem;
    }
    .papercactus__whatFoxNShadow-information-container p {
        font-size: 1rem;
    }

    .papercactus__information-leftside img {
        width:200px;
        height:200px; 
    }

    .papercactus__pressKit {
        width:725px;
    }

    .news-card {
        width:250px;
    }

    .news-card img {
        width:250px;
    }

    .papercactus__information-news {
        justify-content: center;
    }

    .papercactus__information-contactTeam-row1 {
        padding-right: 50px;
    }

    .papercactus__information-contactTeam-row2 {
        padding-left: 50px;
    }
}

@media screen and (max-width: 550px) {
    .FnSpressKitGallery__style img {
        height: 140px;
        width: 140px;
    }

    .papercactus__whatFoxNShadow-information-container {
        flex-direction: column;
        align-items: center;
    }
    

    .papercactus__information-leftside {
        margin-right:0px;
    }

    .papercactus__pressKit {
        width:600px;
    }

    .news-card {
        width:200px;
    }

    .news-card img {
        width:200px;
    }
}

@media screen and (max-width: 450px) {
    .papercactus__pressKit {
        width:550px;
    }

    .news-card {
        width:175px;
    }

    .news-card img {
        width:175px;
    }

    .papercactus__information-contactTeam p {
        font-size: 1rem !important;
    }

    .papercactus__information-contactTeam-row1 {
        padding-right: 25px;
    }

    .papercactus__information-contactTeam-row2 {
        padding-left: 25px;
    }
}