.papercactus__footer-links_div p:hover {
    color:#fff;
}

.footer-spacing {
    letter-spacing: .15rem;
    font-size: 1.2rem;
}

.papercactus__footer {
    background-color: #222;
    z-index: 100;
    position: relative;
}

.papercactus__footer-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap:wrap;
    flex-direction: row;
    width: 100%;
    text-align: left;
}

.papercactus__footer-links div {
    width: 250px;
    margin: 1rem;
}

.papercactus__footer-links_logo {
    display: flex;
    flex-direction: column;
}

.papercactus__footer-links_logo img {
    width: 150px;
    height: 70px;

    margin-bottom: 1rem;
}

.papercactus__footer-links_logo p {
    font-family: var(--font-family2);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    text-align: left;
}

.papercactus__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.papercactus__footer-links_div h4 {
    font-family: var(--font-family2);
    font-size: 16px;
    line-height: 16px;
    color: #fff;
}

.papercactus__footer-links_div p {
    font-family: var(--font-family2);
    font-size: 16px;
    line-height: 14px;
    color: #8a8a8a;
    text-align: left;

    margin: 0.5rem 0;
    cursor: pointer;
}

.papercactus__footer-copyright {
    margin-top: 2rem;
    text-align:center;
    width: 100%; 
}

.papercactus__footer-copyright p {
    font-family: var(--font-family2);
    font-size: 12px;
    line-height: 14px;
    color: #fff;
}

.papercactus__footer-mobile {
    display:none;
    margin-top:25px;
}

@media screen and (max-width: 1320px) {
    .papercactus__footer-links {
        flex-wrap: nowrap;
        justify-content: center;
    }
}

@media screen and (max-width: 850px) {
    .papercactus__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .papercactus__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
    
    .papercactus__footer-links div {
        margin: 1rem 0;
    }

    .papercactus__footer-btn p {
        font-size: 14px;
        line-height: 42px;
    }

    .papercactus__footer-links_logo img {
        width: 100px;
        height: 48px;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 475px) {
    .papercactus__footer-desktop {
        display: none;
    }
    .papercactus__footer-mobile {
        display:flex;
        height:140px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .papercactus__footer-links_logo {
        align-items: center;
    }
    .papercactus__footer-links_logo img {
        margin-bottom:5px;
    }
}