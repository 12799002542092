.papercactus__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222222;
    padding: 0.3rem 6rem;
    width:100vw;
}


.papercactus__navbar_loading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222222;
    padding: 0.3rem 6rem;
    z-index: 102;
    width:100vw;
    top:0px;
    position:absolute;
}

.papercactus__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.papercactus__navbar-links_logo {
    margin-right: 2rem;
}

.papercactus__navbar-links_logo img {
    width: 150px;
    height: 70px;
}

.papercactus__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.papercactus__navbar-socials {
    padding-left:25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color:#8a8a8a;
    font-size:1.4em;
}

.papercactus__navbar-socials i:hover { 
	color:#fff;
    transition: 0.1s ease-in-out;
}

.papercactus__navbar-socials i {
    margin-left:25px;
    font-size:1.2em;
    transition: 0.1s ease-in-out;
}

.papercactus__navbar-links_container p,
.papercactus__navbar-menu_container p {
    color: #8a8a8a;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
    transition: 0.1s ease-in-out;
}

.papercactus__navbar-links_container p:hover,
.papercactus__navbar-menu_container p {
    color:#fff;
    transition: 0.1s ease-in-out;
}

.papercactus__navbar-menu {
    margin-left: 1rem;
    z-index:1000;
    display: none;
    position: relative;
}

.papercactus__navbar-menu svg {
    cursor: pointer;
}

.papercactus__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #222222;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 150px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.papercactus__navbar-menu_container p {
    margin: 1rem 0;
}

.papercactus__navbar-menu_container-links-socials {
    display: none;
}

.navbar-spacing {
    letter-spacing: .15rem;
    font-size: 1.2rem;
}

@media screen and (max-width:1050px) {
    .papercactus__navbar-links_container {
        display: none;
    }
    .papercactus__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width:700px) {
    .papercactus__navbar {
        padding: 2rem 4rem;
    }
    
    .papercactus__navbar_loading {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width:550px) {
    .papercactus__navbar {
        padding: 2rem;
    }

    .papercactus__navbar-socials {
        display:none;
    }
    
    .papercactus__navbar-menu_container {
        top: 20px;
    }

    .papercactus__navbar-menu_container-links-socials {
        display: block;
    }
}